<!-- eslint-disable vue/order-in-components -->
<template>
  <Popup
    title="멤버십 추가"
    :maxWidth="500"
    closeBtnText="닫기"
    compeleteBtnText="추가"
    :isDisabeldCompeleteBtn="validatedResult"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete', joinDataSync)"
  >
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>
            퍼핀 회원 UID<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <Input
              :value.sync="joinDataSync.firfin_user_uid"
              :isDisabled="isFixedUid"
            />
          </td>
        </tr>
        <tr>
          <th>
            멤버십 종류<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <Selectbox
              :dataList="membershipTypeList"
              :value.sync="joinDataSync.membership_id"
            />
          </td>
        </tr>
        <tr>
          <th>
            결제타입<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <Selectbox
              :dataList="'membership_purchase_method_type' | getSelectDataList"
              :value.sync="joinDataSync.purchase_method_type"
            />
          </td>
        </tr>
        <tr>
          <th>구매금액</th>
          <td>
            <Input
              :value.sync="joinDataSync.amount"
              valueType="numberComma"
              :isBlockUnValueType="true"
            />
          </td>
        </tr>
        <tr>
          <th>
            token<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
          </th>
          <td>
            <div class="group_form">
              <Input :value.sync="joinDataSync.token" />
              <Button
                btnSize="small"
                btnStyle="secondary"
                text="생성"
                @onClickBtn="onClickGetUuid()"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            멤버십 시작일<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <DatePicker :value.sync="joinDataSync.start_at" />
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { uuid } from 'vue-uuid';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

export default {
  name: 'MembershipJoinPopup',
  components: {
    Popup,
    TableView,
    IconSvg,
    Input,
    Selectbox,
    DatePicker,
    Button,
  },
  props: {
    joinData: Object,
    isFixedUid: Boolean,
    membershipTypeList: Array,
  },
  data() {
    return {
      validData: {
        firfin_user_uid: {
          value: 'firfin_user_uid',
          valueType: '',
          isValid: false,
        },
        membership_id: {
          value: 'membership_id',
          valueType: '',
          isValid: false,
        },
        purchase_method_type: {
          value: 'purchase_method_type',
          valueType: '',
          isValid: false,
        },
        token: {
          value: 'token',
          valueType: '',
          isValid: false,
        },
        start_at: {
          value: 'start_at',
          valueType: '',
          isValid: false,
        },
      },
    };
  },
  computed: {
    joinDataSync: {
      get() {
        return this.joinData;
      },
      set(val) {
        this.$emit('updata:joinData', val);
      },
    },
    validatedResult() {
      return pageValidated(this.validData, this.joinDataSync);
    },
  },
  methods: {
    onClickGetUuid() {
      this.joinDataSync.token = uuid.v1();
    },
  },
};
</script>
<!-- <style scoped>
</style> -->
