<template>
  <Popup
    title="멤버십 구매내역 상세"
    :maxWidth="800"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickClose')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col/>
        <col style="width:140px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">멤버십 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>퍼핀 회원 ID</th>
          <td><span class="txt_tbl">{{ data.user_id }}</span></td>
          <th>퍼핀 회원 UID</th>
          <td><span class="txt_tbl">{{ data.firfin_user_uid }}</span></td>
        </tr>
        <tr>
          <th>회원 이름</th>
          <td colspan="3"><span class="txt_tbl">{{ data.name }}</span></td>
        </tr>
        <tr>
          <th>token</th>
          <td><span class="txt_tbl">{{ data.token }}</span></td>
          <th>계정 상태</th>
          <td :class="`tc_${ $options.filters.convertIdToColor(data.user_status, 'user_status_name') }`">
            {{ data.user_status | convertIdToText('user_status_name') }}
          </td>
        </tr>
        <tr>
          <th>회원 구분</th>
          <td :class="`tc_${ $options.filters.convertIdToColor(data.user_role, 'user_role') }`">
            {{ data.user_role | convertIdToText('user_role') }}
          </td>
          <th>휴대전화 번호</th>
          <td><span class="txt_tbl">{{ data.phone | convertPhoneNember }}</span></td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col/>
        <col style="width:140px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">멤버십 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>멤버십 ID</th>
          <td>{{ data.user_membership_id }}</td>
          <th>멤버십 UID</th>
          <td>{{ data.user_membership_uid }}</td>
        </tr>
        <tr>
          <th>멤버십종류</th>
          <td>
            {{ data.membership | convertIdToText('membership_type') }}
          </td>
          <th>멤버십상태</th>
          <td :class="`tc_${ $options.filters.convertIdToColor(data.user_membership_status, 'user_status_name') }`">
            {{ data.user_membership_status | convertIdToText('user_status_name') }}
          </td>
        </tr>
        <tr>
          <th>기간옵션</th>
          <td>{{ data.membership_title }}</td>
          <th>자동결제옵션</th>
          <td>{{ data.membership_subscription_type }}</td>
        </tr>
        <tr>
          <th>결제타입</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="data.purchase_method_type | convertIdToText('membership_purchase_method_type')"
                :badgeStyle="data.purchase_method_type | convertIdToColor('membership_purchase_method_type')"
                badgeSize="small"
                :badgeFill="false" />
            </span>
          </td>
          <th>결제상태</th>
          <td :class="`tc_${ $options.filters.convertIdToColor(data.payment_status, 'membership_payment_status') }`">
            {{ data.payment_status | convertIdToText('membership_payment_status') }}
          </td>
        </tr>
        <tr>
          <th>멤버십 기한</th>
          <td colspan="3">
            <span class="txt_tbl">{{data.membership_start_date_time | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</span>
            <span class="txt_tbl"> ~ </span>
            <span :class="['txt_tbl', isEndDate]">{{data.membership_end_date_time | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</span>
          </td>
        </tr>
        <tr>
          <th>멤버쉽 구매금액</th>
          <td>{{ data.amount | convertNumberToComma(false,'0','원') }}</td>
          <th>멤버십 구매일시</th>
          <td>{{ data.purchased_at | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
        <tr>
          <th>멤버십 구매취소 환불금액</th>
          <td>{{ data.cancel_amount | convertNumberToComma(false,'0','원') }}</td>
          <th>멤버십 구매취소일시</th>
          <td>{{ data.cancel_at | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">멤버십 구매취소</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>멤버십 구매취소 환불금액</th>
          <td>
            <div class="group_form">
              <Input
                placeholder="환불금액 기록용"
                valueType="numberComma"
                :isBlockUnValueType="true"
                :value.sync="cancelData.cancel_amount"/>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text="구매취소"
                @onClickBtn="$emit('onClickComplete',cancelData)"/> 
            </div>
            <p class="desc_info">💡 실제 환불되는 금액이 아닌 기록용입니다</p>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertDateFormat, dateDiff } from "@lemontree-ai/lemontree-admin-common-front/utils/dateUtils";

export default {
  name:'MembershipPaymentDetailPopup',
  props:{
    data: Object
  },
  components:{
    Popup,
    TableView,
    Badge,
    Input,
    Button
  },
  computed:{
    isEndDate(){
      let today = convertDateFormat(new Date(), 'YYYY-MM-DD');
      const passDay = dateDiff(today,this.data.membership_end_at) > 0;
      const passDayColor = passDay ? 'tc_red' : '';
      return passDayColor
    }
  },
  data(){
    return{
      cancelData : {
        cancel_amount:null
      }
    }
  },
}
</script>
<!-- <style scoped>
</style> -->